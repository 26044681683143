<template>
  <div class="w-full" v-loading="loading">
    <div class="pb-2">
      <div class="title">Time Sheet</div>
      <div class="w-full" style="overflow-x: auto;">
        <el-table :data="tableData" style="min-width: 380px; width: 100%">
          <el-table-column label="Loading">
            <template slot-scope="scope">
              <span class="asterisk">
                <span v-if="scope.$index < 3">*</span>
              </span>
              {{ scope.row.formDescription }}
            </template>
          </el-table-column>
          <el-table-column label="Date and Time" width="270">
            <template slot-scope="scope">
              <div class="flex">
                <el-date-picker v-model="scope.row.getTime" class="mr-4" prefix-icon="el-icon-date"
                  size="mini" type="datetime" :disabled="disabled"
                >
                </el-date-picker>
                <el-button plain type="primary" size="mini" @click="fillCurrentTime(scope.row)" :disabled="disabled">Now</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>
<script>
import {pilotSaveOrUpdateTimesheet, selectFromById} from "@/services/form";
import dayjs from "dayjs";
export default {
  name: "TimeSheet",
  props: {
    id: {
      type: Number
    },
    disabled: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      tableData: [],
      dataForm: {},
      loading: true,
    }
  },
  async created() {
    await this.getDetail()
    this.loading = false
  },
  mounted() {
    this.$emit('save', this.save)
  },
  methods: {
    async getDetail() {
      await selectFromById({ nId: this.id, fId: 18})
      .then(res => {
        this.dataForm = {...res.data}
        this.dataForm.list.forEach(item => { item.beforeAfterFlag = item.orderIndex <= this.beforeLength ? 0 : 1 });
        this.dataForm.listItem = this.dataForm.listItem && this.dataForm.listItem.length > 0 ? this.dataForm.listItem : [{}]
        this.tableData = this.dataForm.list.filter( (item, index) => this.onlyFirst3Last3(index))
      })
    },
    onlyFirst3Last3( index ) {
      return index < 3 || index >= (this.dataForm.list.length - 3)
    },
    fillCurrentTime(row) {
      row.getTime = dayjs(new Date()).format('YYYY-MM-DD HH:mm:ss')
    },
    async save() {
      this.loading = true

      const list = []
      this.tableData.map(item => {
        list.push({ formId: item.formId,  getTime: item.getTime })
      })
      
      const data = {
        nominationId: this.id,
        list: list,
        beforeAfterFlag: 1
      }

      await pilotSaveOrUpdateTimesheet(data).then(res=>{
        this.getDetail()
        this.$message.success('Save Time Sheet Success')
      }).catch(()=>{
      }).finally(() => {
        this.loading = false
      })
    }
  },
}
</script>
<style lang="scss" scoped>
.title {
  font-family: 'Roboto';
  color: #1D1B20;
  font-size: 14px;
  font-weight: 800;
  margin-bottom: 10px;
}
.asterisk {
  color: red;
  display: inline-block;
  width: 5px;
}
::v-deep.el-table::before {
  height: 0px;
}
::v-deep.el-table thead tr {
  background-color: #F4F8FB;
}
::v-deep.el-table th.el-table__cell.is-leaf {
  background-color: transparent;
  font-size: 12px;
  font-weight: 800;
  color: #1D1B20;
}
::v-deep.el-table td.el-table__cell {
  font-size: 11px;
  font-weight: 600;
  color: #1D1B20;
  border: 1px solid #82889C;
  border-bottom: none;
}
::v-deep.el-table td.el-table__cell .cell {
  font-size: 11px;
  font-weight: 600;
  color: #1D1B20;
}
::v-deep.el-table td.el-table__cell:first-child {
  border-right: none;
}
::v-deep.el-table td.el-table__cell:last-child {
  border-left: none;
}
::v-deep.el-table tr:last-child td.el-table__cell {
  border-bottom: 1px solid #82889C;
}
</style>