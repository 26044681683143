<template>
  <div class="w-full pr-2 pl-2" style="background-color: white; border-radius: 10px;">
    <div class="w-full" style="overflow-x: scroll;">
      <div class="statusLine md:pl-5 md:pr-5 pt-10 pb-10 flex justify-between flex-nowrap text-xs">
        <div v-for="(item,index) in list" :key="item.id"  :class="item.type" :style="returnNameStyle(item)" class="item">
          <div class="name">{{ item.name }}</div>
          <div class="circle" :class="returnClass(item,index)">
            <div class="baby"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'StatusLine',
  props: {
    status : {
      type: Number,
      default: 0,
    },
    loadingType: {
      type: Number,
      default: null,
    },
  },
  created() {
    this.setStageId()
  }, 
  data() {
    return {
      // reference purpose
      statusList:[
        { status: 0, name: 'Created', stageId: -1 },
        { status: 1, name: 'Barge Ready', stageId: -1 },
        { status: 2, loadingType: null, name: 'Jetty Assigned', stageId: -1 },
        { status: 2, loadingType: 0, name: 'Booked', stageId: 0 },
        { status: 2, loadingType: [1,2,3,4,5,6,7], name: 'Loading Started', stageId: 1 },
        { status: 3, signType: 0, name: 'Loading Completed', stageId: 2 },
        { status: 3, signType: [1,2], name: 'Signing In Progress', stageId: 2 },
        { status: 4, name: 'Completed', stageId: 3 },
        { status: 5, name: 'Cancelled', stageId: -1 },
      ],
      stageId: -1,
      list: [
        {
          name: 'Booked',
          id: 0,
          size: 'big',
          type: 'top'
        },
        {
          name: 'Loading Started',
          id: 1,
          size: 'big',
          type: 'top'
        },
        {
          name: 'Completed',
          id: 3,
          size: 'big',
          type: 'top'
        },
      ],
      cls: {
        inactive: {
          fontWeight: 400,
          fontSize: '12px',
          color: '#8F8F8F',
          flex: 1,
        },
        active: {
          fontWeight: 500,
          fontSize: '14px',
          color: '#1D1B20',
          flex: 1.5
        },
        hasFinish: {
          fontWeight: 400,
          fontSize: '12px',
          color: '#49454F',
          flex: 1
        }
      },
    }
  },
  methods: {
    setStageId() {
      if( this.isBooked() ) {
        this.stageId = 0
      } else if( this.isLoadingStarted() ) {
        this.stageId = 1
      } else if( this.isLoadingCompleted() ) {
        this.stageId = 2
      } else if( this.isCompleted() ) {
        this.stageId = 3
      } else {
        this.stageId = -1
      }
    },
    isBooked() {
      return this.status == 2 && this.loadingType == 0
    },
    isLoadingStarted() {
      return this.status == 2 && ! this.isBooked() && ! this.isLoadingCompleted()
    },
    isLoadingCompleted() {
      return this.status == 3
    },
    isCompleted() {
      return this.status == 4
    },
    returnClass(item, index) {
      const arr = [item.size, index === this.list.length - 1 ? 'end' : (index === 0 ? 'start' : '')]
      if(this.stageId != -1) {
        if(this.stageId == 3) {
          arr.push('completed', 'hasFinish')
        } else if(this.stageId > item.id) {
          arr.push('hasFinish')
        } else if(this.stageId == item.id) {
          arr.push('active')
        }
      }
      return arr
    },
    returnNameStyle(item) {
      if(this.stageId == -1) {
        return this.cls.inactive
      } else if(this.stageId > item.id || this.stageId == 3) {
        return this.cls.hasFinish
      } else if(this.stageId == item.id) {
        return this.cls.active
      } else {
        return this.cls.inactive
      }
    },
  }
}
</script>
<style scoped lang="scss">
.statusLine {
  width: 100%;
  background-color: white;
  align-items: center;
  .item {
    display: flex;
    justify-content: center;
    position: relative;
    align-items: center;
    height: 20px;
    color: #CFCFCF;

    .name {
      text-wrap: nowrap;
      width: 100%;
      text-align: center;
      position: absolute;
      left: 0;
      top: calc(-100% - 5px);
    }

    .circle {
      width: 20px;
      height: 20px;
      border: 4px solid #CFCFCF;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      i {
        font-size: 12px;
        color: #fff;
      }

      .baby {
        width: 0px;
        height: 0px;
      }
    }

    .circle.hasFinish {
      border: 2px solid #1CBB19;
    }

    .circle.active {
      border: 2px solid #008CDB;
    }

    .circle:not(.end)::after {
      content: '';
      display: block;
      width: calc(50% - 10px);
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(12px) translateY(-50%);
      height: 2px;
      background-color: #CFCFCF;
      z-index: 999;
      border-radius: 4px;
    }

    .small:not(.end)::after {
      transform: translateX(10px) translateY(-50%);
    }

    .circle:not(.start)::before {
      content: '';
      display: block;
      width: calc(50% - 10px);
      position: absolute;
      right: 50%;
      top: 50%;
      transform: translateX(-12px) translateY(-50%);
      height: 2px;
      background-color: #CFCFCF;
      z-index: 999;
      border-radius: 4px;
    }

    .small:not(.start)::before {
      transform: translateX(-10px) translateY(-50%);
    }

    .hasFinish::after {
      background-color: #1CBB19 !important;
    }

    .hasFinish::before {
      background-color: #1CBB19 !important;
    }

    .active::before {
      background-color: #1CBB19 !important;
    }

    // for loading form (before & after) portion
    // dashed line
    .dashed::before, .dashed::after {
      background-color: #FFFFFF !important;
      background: linear-gradient(to right, transparent 50%, #FFFFFF 50%), linear-gradient(to right, #CFCFCF, #CFCFCF);
      background-size: 15px 2px, 100% 2px;
    }
    
    .dashed.completed::before, .dashed.completed::after {
      background: linear-gradient(to right, transparent 50%, #FFFFFF 50%), linear-gradient(to right, #1CBB19, #1CBB19);
      background-size: 15px 2px, 100% 2px;
    }

    .active.dashed::before {
      background: linear-gradient(to right, transparent 50%, #FFFFFF 50%), linear-gradient(to right, #1CBB19, #1CBB19);
      background-size: 0px 2px, 100% 2px;
    }

    .loadingFormStart.dashed::before, .loadingFormEnd.dashed::after {
      background-size: 0px 2px, 100% 2px;
    }

    .loadingFormEnd.dashed::before {
      background: linear-gradient(to right, transparent 50%, #FFFFFF 50%), linear-gradient(to right, #CFCFCF, #CFCFCF);
      background-size: 15px 2px, 100% 2px;
    }

    .loadingFormEnd.dashed.completed::before {
      background: linear-gradient(to right, transparent 50%, #FFFFFF 50%), linear-gradient(to right, #1CBB19, #1CBB19);
      background-size: 15px 2px, 100% 2px;
    }

    .hasFinish.loadingFormEnd.dashed::after {
      background: linear-gradient(to right, transparent 50%, #FFFFFF 50%), linear-gradient(to right, #1CBB19, #1CBB19);
      background-size: 0px 2px, 100% 2px;
    }

    .big {
      width: 25px;
      height: 25px;

      i {
        font-size: 14px;
      }
    }

    .hasFinish {
      border-color: #1CBB19;
      background-color: #1CBB19;

      .baby {
        width: 70%;
        height: 70%;
        background-color: white;
        border-radius: 50%;
      }
    }

    .active {
      border-color: 2px solid #008CDB;
      background-color: #008CDB;

      .baby {
        width: 90%;
        height: 90%;
        border: 2px solid white;
        background-color: #008CDB;
        border-radius: 50%;
      }
    }
  }

  .item.btm {
    .name {
      top: unset;
      bottom: calc(-100% - 5px);
    }
  }
}

.children {
  display: flex;
  align-items: center;

  .circle-children {
    width: 12px;
    height: 12px;
    border: 2px solid #CFCFCF;
    border-radius: 50%;
    margin-right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    i {
      color: #fff;
    }

    .baby {
      width: 70%;
      height: 70%;
      background-color: white;
      border-radius: 50%;
    }
  }

  .circle-children.active {
    border-color: transparent;
    background-color: #008CDB;
  }

  .isActive.hasFinish {
    border-color: transparent;
    background-color: #1CBB19;
  }

  //.circle-children.big {
  //  width: 20px;
  //  height: 20px;
  //}
}
</style>
